<template>
	<v-layout>
		<v-flex xs12>
			<PledgeFormBreadCrumbs v-if="pledgeFormState.pledgeForm.campaignId"></PledgeFormBreadCrumbs>
			<component :is="currentpledgeFormStep" v-if="pledgeFormLoaded" ref="currentFormStep"></component>
			<div class="text-right">
				<r-btn v-if="!prevPage && isNewpledgeForm" href="Pledge/Campaigns" target="_parent" color="default">Cancel</r-btn>
				<r-btn v-if="prevPage && isNewpledgeForm" class="ml-2" :to="{ name: 'pledge-form', params: { page: prevPage, campaignId: pledgeFormState.pledgeForm.campaignId }, query: { isNew: true } }" color="default">
					Back
				</r-btn>
				<r-btn v-if="prevPage && !isNewpledgeForm" class="ml-2" :to="{ name: 'pledge-form', params: { page: prevPage, campaignId: pledgeFormState.pledgeForm.campaignId } }" color="default">Cancel</r-btn>
				<r-btn v-if="!showSaveButton && nextPage" class="ml-2" @click="goToNextPage">Save & Continue</r-btn>
				<r-btn v-if="showSaveButton" class="ml-2" @click="saveAndExitpledgeForm">
					Save
				</r-btn>
			</div>
		</v-flex>
	</v-layout>
</template>

<script>
import GeneralUse from '@/components/giving/pledgeForms/GeneralUse.vue';
import GeneralSettings from '@/components/giving/pledgeForms/GeneralSettings.vue';
import Design from '@/components/giving/pledgeForms/Design.vue';
import ThankYouMessage from '@/components/giving/pledgeForms/ThankYouMessage.vue';
import PledgeFormBreadCrumbs from '@/components/giving/pledgeForms/PledgeFormBreadCrumbs.vue';

export default {
	components: {
		GeneralSettings,
		Design,
		ThankYouMessage,
		PledgeFormBreadCrumbs,
		GeneralUse
	},
	data() {
		return {
			pledgeFormState: _REALM.STORE.pledgeFormStore.state,
			fundState: _REALM.STORE.fundStore.state,
			currentPage: _REALM.VM.$route.params.page,
			pledgeFormsLoaded: false,
			pledgeFormLoaded: false
		};
	},
	computed: {
		currentpledgeFormStep() {
			if (this.currentPage === 'general-use') {
				return 'GeneralUse';
			} else if (this.currentPage === 'general-settings') {
				return 'GeneralSettings';
			} else if (this.currentPage === 'design') {
				return 'Design';
			} else if (this.currentPage === 'thank-you-message') {
				return 'ThankYouMessage';
			}

			return 'GeneralUse';
		},
		nextPage() {
			if (this.currentPage === 'general-use') {
				return null;
			} else if (this.currentPage === 'general-settings') {
				return 'design';
			} else if (this.currentPage === 'design') {
				return 'thank-you-message';
			} else if (this.currentPage === 'thank-you-message') {
				return null;
			}

			return null;
		},
		prevPage() {
			if (this.currentPage === 'general-use') {
				return null;
			} else if (this.currentPage === 'general-settings') {
				return _REALM.VM.$route.query.isNew ? null : 'general-use';
			} else if (this.currentPage === 'design') {
				return _REALM.VM.$route.query.isNew ? 'general-settings' : 'general-use';
			} else if (this.currentPage === 'thank-you-message') {
				return _REALM.VM.$route.query.isNew ? 'design' : 'general-use';
			}

			return null;
		},
		showSaveButton() {
			return (_REALM.VM.$route.params.campaignId && this.currentPage !== 'general-use' && !_REALM.VM.$route.query.isNew) || this.currentPage === 'thank-you-message';
		},
		isNewpledgeForm() {
			return _REALM.VM.$route.query.isNew;
		}
	},
	created() {
		var self = this;
		_REALM.STORE.pledgeFormStore.staffListPledgeForms().then(function() {
			self.pledgeFormsLoaded = true;
		});

		_REALM.STORE.pledgeFormStore.getPledgeForm(_REALM.VM.$route.params.campaignId).then(function(pledgeForm) {
			if (pledgeForm.pledgeFormId == undefined) {
				_REALM.STORE.campaignStore.getCampaign(_REALM.VM.$route.params.campaignId).then(function(campaign) {
					_REALM.STORE.pledgeFormStore.setPledgeFormDefaults(campaign);
				});
			}

			self.pledgeFormLoaded = true;
		});
	},
	methods: {
		goToNextPage() {
			var self = this;
			if (self.$refs.currentFormStep.validate()) {
				self.savePledgeForm().then(function(pledgeForm) {
					_REALM.VM.$router.push({
						name: 'pledge-form',
						params: {
							page: self.nextPage,
							campaignId: pledgeForm.campaignId
						},
						query: {
							isNew: true
						}
					});
				});
			}
		},
		saveAndExitpledgeForm() {
			var self = this;
			if (self.$refs.currentFormStep.validate()) {
				if (self.pledgeFormState.pledgeForm.pledgeFormId) {
					self.savePledgeForm().then(function(pledgeForm) {
						self.afterAddOrSave(pledgeForm, 'update');
					});
				} else {
					self.savePledgeForm().then(function(pledgeForm) {
						self.afterAddOrSave(pledgeForm, 'add');
					});
				}
			}
		},
		savePledgeForm() {
			if (this.pledgeFormState.pledgeForm.pledgeFormId) {
				return _REALM.STORE.pledgeFormStore.updatePledgeForm(this.pledgeFormState.pledgeForm);
			} else {
				return _REALM.STORE.pledgeFormStore.addPledgeForm(this.pledgeFormState.pledgeForm);
			}
		},
		afterAddOrSave(pledgeForm, type) {
			_REALM.VM.$router.push({
				name: 'pledge-form',
				params: {
					page: 'general-use',
					campaignId: pledgeForm.campaignId
				}
			});

			_REALM.TOAST.success(`Pledge form has been ${type === 'add' ? 'added' : 'saved'}.`);
		}
	}
};
</script>
