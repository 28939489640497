<style lang="scss">
@import '@/scss/variables.scss';
.donor-preview-wrapper {
	.browser-row {
		padding: 10px;
		background: #f1f1f1;
		border-top-left-radius: 4px;
		border-top-right-radius: 4px;
	}

	.browser-column {
		float: left;
	}

	.browser-left {
		width: 15%;
	}

	.browser-right {
		width: 10%;
	}

	.browser-middle {
		width: 75%;
	}

	.browser-row::after {
		display: table;
		clear: both;
		content: '';
	}

	.browser-dot {
		display: inline-block;
		width: 12px;
		height: 12px;
		margin-top: 4px;
		margin-left: 3px;
		border-radius: 50%;
	}

	.browser-url {
		width: 100%;
		height: 25px;
		padding: 5px;
		margin-top: -8px;
		color: #666;
		background-color: white;
		border: none;
		border-radius: 3px;
	}

	.browser-bar {
		display: block;
		width: 17px;
		height: 3px;
		margin: 3px 0;
		background-color: #aaa;
	}
}
</style>

<template>
	<v-layout wrap class="donor-preview-wrapper">
		<v-flex>
			<div class="browser-row">
				<div class="browser-column browser-left">
					<span class="browser-dot" style="background:#ED594A;"></span>
					<span class="browser-dot" style="background:#FDD800;"></span>
					<span class="browser-dot" style="background:#5AC05A;"></span>
				</div>
				<div class="browser-column browser-middle">
					<input :value="givingFormUrl" class="browser-url" type="text" />
				</div>
				<div class="browser-column browser-right">
					<div style="float:right">
						<span class="browser-bar"></span>
						<span class="browser-bar"></span>
						<span class="browser-bar"></span>
					</div>
				</div>
			</div>

			<div class="content">
				<v-flex :class="useDarkOverlayScheme" xs12>
					<DonorNav></DonorNav>
					<r-card :dark="useDarkBodyScheme">
						<FormHeader v-if="formType === 'Giving'" slot="media"></FormHeader>
						<PledgeFormHeader v-if="formType === 'Pledge'" slot="media"></PledgeFormHeader>
						<DonorForm v-if="formType === 'Giving'"></DonorForm>
						<DonorPledgeForm v-if="formType === 'Pledge'"></DonorPledgeForm>
					</r-card>
				</v-flex>
				<v-flex :class="useDarkOverlayScheme" xs12>
					<DonorFooter></DonorFooter>
				</v-flex>
			</div>
		</v-flex>
	</v-layout>
</template>

<script>
import DonorForm from '@/components/giving/donorForm/DonorForm.vue';
import DonorNav from '@/components/giving/sharedForms/DonorNav.vue';
import FormHeader from '@/components/giving/donorForm/FormHeader.vue';
import DonorFooter from '@/components/giving/sharedForms/DonorFooter.vue';
import PledgeFormHeader from '@/components/giving/donorPledgeForm/FormHeader.vue';
import DonorPledgeForm from '@/components/giving/donorPledgeForm/DonorForm.vue';

export default {
	components: {
		DonorForm,
		DonorNav,
		FormHeader,
		DonorFooter,
		PledgeFormHeader,
		DonorPledgeForm
	},
	props: {
		formType: {
			default: 'Giving',
			type: String,
			validator: function(value) {
				return ['Giving', 'Pledge'].includes(value);
			}
		}
	},
	data() {
		return {
			givingFormState: _REALM.STORE.givingFormStore.state,
			pledgeFormState: _REALM.STORE.pledgeFormStore.state
		};
	},
	computed: {
		useDarkOverlayScheme() {
			if (this.formType === 'Giving') {
				return this.givingFormState.givingForm.useDarkOverlayScheme ? 'donor-form-dark' : 'donor-form-light';
			}

			return this.pledgeFormState.pledgeForm.useDarkOverlayScheme ? 'donor-form-dark' : 'donor-form-light';
		},
		useDarkBodyScheme() {
			if (this.formType === 'Giving') {
				return this.givingFormState.givingForm.useDarkBodyScheme;
			}

			return this.pledgeFormState.pledgeForm.useDarkBodyScheme;
		},
		givingFormUrl() {
			return this.formType === 'Giving' ? _REALM.STORE.givingFormStore.getGivingFormBaseUrl() + this.givingFormState.givingForm.urlPath : _REALM.STORE.pledgeFormStore.getPledgeFormBaseUrl() + this.pledgeFormState.pledgeForm.urlPath;
		}
	},
	created() {
		_REALM.STORE.givingFormStore.state.transactionAccount.individualId = _REALM.STORE.accountStore.state.authAccount.individualId;
	}
};
</script>
