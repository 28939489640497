<template>
	<v-layout>
		<v-flex xs12>
			<r-breadcrumbs :links="links"></r-breadcrumbs>
			<v-divider></v-divider>
		</v-flex>
	</v-layout>
</template>

<script>
export default {
	data() {
		return {
			pledgeFormState: _REALM.STORE.pledgeFormStore.state,
			campaign: {}
		};
	},
	computed: {
		links() {
			var links = [
				{
					route: `Pledge/Campaigns`,
					text: 'Pledge Campaigns',
					exact: true
				},
				{
					route: `Pledge/Campaign/${this.pledgeFormState.pledgeForm.campaignId}`,
					text: this.campaign.fund ? this.campaign.fund.name : null,
					exact: true
				}
			];

			if (this.pledgeFormState.pledgeForm.pledgeFormId) {
				if (_REALM.VM.$route.params.page != 'general-use') {
					links.push({
						name: 'pledge-form',
						text: 'General Use',
						exact: true,
						params: {
							page: 'general-use',
							campaignId: this.pledgeFormState.pledgeForm.campaignId
						}
					});
				} else {
					links.push({
						text: 'General Use'
					});
				}
			}

			if (_REALM.VM.$route.params.page == 'design') {
				links.push({
					text: 'Design'
				});
			}

			if (_REALM.VM.$route.params.page == 'general-settings') {
				links.push({
					text: 'General Settings'
				});
			}

			if (_REALM.VM.$route.params.page == 'thank-you-message') {
				links.push({
					text: 'Thank You Message'
				});
			}

			return links;
		}
	},
	created() {
		this.getCampaign();
	},
	methods: {
		getCampaign() {
			var self = this;
			_REALM.STORE.campaignStore.getCampaign(_REALM.VM.$route.params.campaignId).then(function(campaign) {
				self.campaign = campaign;
			});
		}
	}
};
</script>
