<style lang="scss">
.general-use-wrapper {
	.color-swatch {
		display: inline-block;
		width: 20px;
		height: 20px;
		vertical-align: bottom;
	}
}
</style>

<template>
	<v-layout wrap class="general-use-wrapper">
		<v-flex md8 order-sm2 order-xs2 order-md1>
			<r-card class="mb-4">
				<template slot="title">
					Share a direct link to the pledge form.
				</template>
				<v-layout>
					<r-text-input :value="pledgeFormUrl" filled readonly></r-text-input>
					<r-btn class="ml-3 mt-3" type="button" @click="copyToClipboard(pledgeFormUrl)">
						Copy
					</r-btn>
				</v-layout>
			</r-card>

			<r-card class="mb-4">
				<template slot="title">
					Embed this pledge form on your website.
				</template>
				<p>Copy and paste the following code as the last item in the &lt;BODY&gt; of the webpage. <r-help-link resource-id="Realm.GivingForm.Embed">Learn more.</r-help-link></p>
				<v-layout>
					<r-text-input :value="embedScript" filled readonly></r-text-input>
					<r-btn class="ml-3 mt-3" type="button" @click="copyToClipboard(embedScript)">
						Copy
					</r-btn>
				</v-layout>
				<p>Include this link on the page you'd like to use to trigger the pledge form.</p>
				<v-layout>
					<r-text-input :value="pledgeFormUrl" filled readonly></r-text-input>
					<r-btn class="ml-3  mt-3" type="button" @click="copyToClipboard(pledgeFormUrl)">
						Copy
					</r-btn>
				</v-layout>
			</r-card>
		</v-flex>

		<v-flex md4 order-sm1 order-xs1 order-md2>
			<r-card>
				<template slot="title">
					General Settings
				</template>
				<template slot="titleActions">
					<r-btn v-if="!pledgeFormState.pledgeForm.dateDeactivated" :to="{ name: 'pledge-form', params: { page: 'general-settings', campaignId: pledgeFormState.pledgeForm.campaignId } }" class="pull-right" small color="default">
						Edit
					</r-btn>
				</template>

				<v-layout row wrap>
					<v-flex xs12>
						<hr class="primary" />
					</v-flex>
					<v-flex md4 xs12 class="font-weight-bold">Unique URL</v-flex>
					<v-flex md8 xs12 class="break-word">{{ pledgeFormUrl }}</v-flex>

					<v-flex md4 xs12 class="font-weight-bold">Default Frequency</v-flex>
					<v-flex md8 xs12 class="break-word">{{ pledgeFormState.pledgeForm.defaultFrequency | enumText('PledgeFrequency') }}</v-flex>

					<v-flex md4 xs12 class="font-weight-bold">Anyone Can Pledge</v-flex>
					<v-flex md8 xs12 class="break-word">{{ pledgeFormState.pledgeForm.unauthenticatedPledgingEnabled | yesNo }}</v-flex>
				</v-layout>
			</r-card>

			<r-card class="mt-4">
				<template slot="title">
					Design
				</template>
				<template slot="titleActions">
					<r-btn v-if="!pledgeFormState.pledgeForm.dateDeactivated" :to="{ name: 'pledge-form', params: { page: 'design', campaignId: pledgeFormState.pledgeForm.campaignId } }" class="pull-right" small color="default">
						Edit
					</r-btn>
				</template>

				<v-layout row wrap>
					<v-flex xs12>
						<hr class="primary" />
					</v-flex>
					<v-flex md4 xs12 class="font-weight-bold">Title</v-flex>
					<v-flex md8 xs12>{{ pledgeFormState.pledgeForm.title }}</v-flex>

					<v-flex md4 xs12 class="font-weight-bold">Subtitle</v-flex>
					<v-flex md8 xs12>{{ pledgeFormState.pledgeForm.primarySubtitle }}</v-flex>

					<v-flex md4 xs12 class="font-weight-bold">Intro message</v-flex>
					<v-flex md8 xs12 class="break-word">{{ pledgeFormState.pledgeForm.introMessage }}</v-flex>

					<v-flex md4 xs12 class="font-weight-bold">Photo</v-flex>
					<v-flex md8 xs12>
						<r-media-image :image-id="pledgeFormState.pledgeForm.pledgeFormId" :width="40" :image-type="imageTypes.GIVING_FORM" :border-radius="logoBorderRadius"></r-media-image>
					</v-flex>

					<v-flex md4 xs12 class="font-weight-bold">Header color</v-flex>
					<v-flex md8 xs12>
						<div :style="{ backgroundColor: pledgeFormState.pledgeForm.headerBackgroundColor }" class="color-swatch"></div>
						{{ pledgeFormState.pledgeForm.headerBackgroundColor }}
					</v-flex>

					<v-flex md4 xs12 class="font-weight-bold">Dark overlay</v-flex>
					<v-flex md8 xs12>{{ pledgeFormState.pledgeForm.useDarkOverlayScheme | yesNo }}</v-flex>

					<v-flex md4 xs12 class="font-weight-bold">Dark form body</v-flex>
					<v-flex md8 xs12>{{ pledgeFormState.pledgeForm.useDarkBodyScheme | yesNo }}</v-flex>

					<v-flex md4 xs12 class="font-weight-bold">Action color</v-flex>
					<v-flex md8 xs12>
						<div :style="{ backgroundColor: pledgeFormState.pledgeForm.accentColor }" class="color-swatch"></div>
						{{ pledgeFormState.pledgeForm.accentColor }}
					</v-flex>

					<v-flex md4 xs12 class="font-weight-bold">Style</v-flex>
					<v-flex md8 xs12>{{ pledgeFormState.pledgeForm.font | capitalizeFirstLetter }}</v-flex>
				</v-layout>
			</r-card>

			<r-card class="mt-4">
				<template slot="title">
					Thank You Message
				</template>
				<template slot="titleActions">
					<r-btn v-if="!pledgeFormState.pledgeForm.dateDeactivated" :to="{ name: 'pledge-form', params: { page: 'thank-you-message', campaignId: pledgeFormState.pledgeForm.campaignId } }" class="pull-right" small color="default">
						Edit
					</r-btn>
				</template>

				<v-layout row wrap>
					<v-flex xs12>
						<hr class="primary" />
					</v-flex>
					<v-flex md4 xs12 class="font-weight-bold">Message</v-flex>
					<v-flex md8 xs12>{{ pledgeFormState.pledgeForm.receiptMessage }}</v-flex>
					<v-flex md4 xs12 class="font-weight-bold">Add Recurrence</v-flex>
					<v-flex md8 xs12>{{ pledgeFormState.pledgeForm.askForOnlineGifts | yesNo }}</v-flex>
				</v-layout>
			</r-card>
		</v-flex>
	</v-layout>
</template>

<script>
export default {
	data() {
		return {
			fundsLoaded: false,
			givingState: _REALM.STORE.givingStore.state,
			siteState: _REALM.STORE.siteStore.state,
			pledgeFormState: _REALM.STORE.pledgeFormStore.state,
			imageTypes: _REALM.STORE.mediaStore.ImageTypes,
			pledgeFormActivateId: 'r-activate-form', //Do not change this value unless the giving-embed.js is changed to match
			pledgeFormAttributeId: 'r-form-id-', //Do not change this value unless the giving-embed.js is changed to match
			pledgeFormAttributeSiteId: 'r-site-id-' //Do not change this value unless the giving-embed.js is changed to match
		};
	},
	computed: {
		pledgeFormUrl() {
			return _REALM.STORE.pledgeFormStore.getPledgeFormBaseUrl() + this.pledgeFormState.pledgeForm.urlPath;
		},
		embedScript() {
			return `<script id="r-embed-script"><\/script><script type="text/javascript">document.getElementById('r-embed-script').src = '${_REALM.CONFIG.StratusHost}/Assets/Embed/giving-embed.js?v=' + Date.now();<\/script>`; // eslint-disable-line no-useless-escape
		},
		logoBorderRadius() {
			return _REALM.STORE.pledgeFormStore.getLogoBorderRadius();
		}
	},
	created() {},
	methods: {
		copyToClipboard(value) {
			_REALM.TOOLS.copyToClipboard(value);
		}
	}
};
</script>
