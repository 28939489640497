<template>
	<v-layout>
		<v-flex xs12>
			<r-card class="mb-4">
				<template slot="title">
					General Settings
				</template>
				<r-form v-if="pledgeFormsLoaded" ref="optionsForm">
					<v-layout row>
						<v-flex xs12 lg5>
							<r-text-input
								v-model="pledgeFormState.pledgeForm.urlPath"
								:prefix="pledgeFormStore.getPledgeFormBaseUrl()"
								:uniqueValue="usedUrlPaths"
								:minlength="1"
								:maxlength="20"
								pattern="[A-Za-z0-9-]"
								disableAutocorrect
								label="Unique URL"
								hint="Unique URL"
								:persistent-hint="true"
								:counter="true"
							></r-text-input>
						</v-flex>
					</v-layout>
					<v-layout row>
						<v-flex xs12 lg5>
							<r-select-list v-model="pledgeFormState.pledgeForm.defaultFrequency" outlined dense :options="defaultFrequencyOptions" label="Default Frequency"></r-select-list>
						</v-flex>
						<v-flex xs12>
							<v-switch v-model="pledgeFormState.pledgeForm.unauthenticatedPledgingEnabled">
								<template v-slot:label>
									Allow pledges from contributors who do not have a profile
									<r-info-tooltip>New profiles will be created for these contributors.</r-info-tooltip>
								</template>
							</v-switch>
						</v-flex>
					</v-layout>
				</r-form>
			</r-card>
		</v-flex>
	</v-layout>
</template>

<script>
export default {
	data() {
		return {
			pledgeFormState: _REALM.STORE.pledgeFormStore.state,
			pledgeFormStore: _REALM.STORE.pledgeFormStore,
			givingState: _REALM.STORE.givingStore.state,
			pledgeFormsLoaded: false,
			isUnique: {
				name: null,
				nameLoading: false,
				urlPath: null,
				urlPathLoading: false
			},
			defaultFrequencyOptions: _REALM.TOOLS.buildSelectList(STRATUS.EnumImports.PledgeFrequency, 'Name', 'Text')
		};
	},
	computed: {
		usedUrlPaths() {
			var self = this;
			if (!self.pledgeFormState.pledgeForms) {
				return [];
			}

			return self.pledgeFormState.pledgeForms
				.filter(item => item.pledgeFormId !== self.pledgeFormState.pledgeForm.pledgeFormId)
				.map(function(item) {
					return item.urlPath.toLowerCase();
				});
		}
	},
	created() {
		this.getPledgeForms();
	},
	methods: {
		validate() {
			return this.$refs.optionsForm.validate();
		},
		getPledgeForms() {
			var self = this;
			return _REALM.STORE.pledgeFormStore.staffListPledgeForms().then(function() {
				self.pledgeFormsLoaded = true;
			});
		}
	}
};
</script>
