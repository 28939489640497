<template>
	<v-layout row wrap>
		<v-flex xs12>
			<r-banner :showBannerOnInit="pledgeFormState.pledgeForms.length === 1" class="mb-4">
				<template slot="icon">
					feedback
				</template>
				<template slot="title">
					What would you like to tell your contributors?
				</template>
				<template slot="text">
					Personalize a message for your contributors to see after they setup a pledge. A thank you message is a great way to express appreciation to your contributors.
				</template>
			</r-banner>

			<r-card class="mb-4">
				<template slot="title">
					Thank You Message
				</template>
				<v-layout row wrap>
					<v-flex xs12>
						<r-form ref="thankYouMessageForm">
							<r-text-area v-model="pledgeFormState.pledgeForm.receiptMessage" maxlength="2000" label="Message" required placeholder="This appreciation message will display to the contributor after they setup a pledge"></r-text-area>
							<v-flex xs12 class="float-right">
								<r-btn class="mt-0" color="default" @click="suggestionsDialog = true">Need Ideas?</r-btn>
							</v-flex>
						</r-form>
					</v-flex>
					<v-flex v-if="showAddGiftOption" xs12>
						<v-switch v-model="pledgeFormState.pledgeForm.askForOnlineGifts">
							<template v-slot:label>
								Allow contributors to add a recurring gift with their pledge
							</template>
						</v-switch>
					</v-flex>
				</v-layout>
			</r-card>
		</v-flex>

		<r-dialog v-model="suggestionsDialog" max-width="700" scrollable max-height="500" type="primary">
			<template slot="title">
				A little thanks goes a long way.
			</template>
			<p>
				Your thank you message can range from a simple show of appreciation to a full acknowledgement of the impact your contributors have made. Below are short, generic messages to start you off that you can personalize to suit your need.
			</p>
			<r-card v-for="(message, index) in messageSuggestions" :key="index" text color="grey" class="mt-4">
				<v-layout align-center justify-center row fill-height>
					<v-flex class="font-weight-medium font-italic" xs10>{{ message }}</v-flex>
					<v-flex class="text-right" xs2>
						<r-btn depressed round @click="setMessage(message)">Use</r-btn>
					</v-flex>
				</v-layout>
			</r-card>
			<template slot="actions">
				<v-spacer></v-spacer>
				<r-btn color="default" @click="suggestionsDialog = false">Close</r-btn>
			</template>
		</r-dialog>
	</v-layout>
</template>

<script>
export default {
	data() {
		return {
			pledgeFormState: _REALM.STORE.pledgeFormStore.state,
			suggestionsDialog: false,
			onlineFunds: [],
			messageSuggestions: ['Your pledge has made a positive difference in the lives of others and our faith. Thank you.', 'Thank you for your support! If you have any questions about your pledge, please contact our church office.', 'Thank you for your continued dedication and support.']
		};
	},
	computed: {
		showAddGiftOption() {
			return _REALM.STORE.givingStore.state.settings.isGivingEnabled && this.onlineFunds.includes(this.pledgeFormState.pledgeForm.fundId);
		}
	},
	created() {
		this.getOnlineFunds();
	},
	methods: {
		validate() {
			if (!this.showAddGiftOption) {
				this.pledgeFormState.pledgeForm.askForOnlineGifts = false;
			}

			return this.$refs.thankYouMessageForm.validate();
		},
		setMessage(message) {
			this.pledgeFormState.pledgeForm.receiptMessage = message;
			this.suggestionsDialog = false;
		},
		getOnlineFunds() {
			var self = this;
			_REALM.STORE.fundStore.listOnline({ filterCampusFunds: false }).then(function(onlineFunds) {
				self.onlineFunds = onlineFunds.map(function(obj) {
					return obj.fundId;
				});
			});
		}
	}
};
</script>
